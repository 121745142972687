$(function(){
  $(".playBtnOuter").on('click', function(event) {
    event.preventDefault();
    
    $("#playerWrap").removeClass('d-none');
    $("#playerImg").addClass('d-none');
    player.playVideo();
  });

  var video = document.getElementsByTagName('video')[0];
  if (typeof(element) != 'undefined' && element != null){
    video.onended = function(e) {
      $("#playerWrap").addClass('d-none');
      $("#playerImg").removeClass('d-none');
    };
  }
})

var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

window.player = null; 
window.onYouTubeIframeAPIReady = function() {
  var videoID = $("#playerImg").find(".playBtnOuter").attr("href");
  // var videoID = getYoutubeID(getURL);
  player = new YT.Player('videoplayer', {
    height: '100%',
    width: '100%',
    videoId: videoID,
    playerVars: {
      rel: 0
    },
    events: {
      'onStateChange': onPlayerStateChange
    }
  });
}

window.onPlayerReady = function(event) { 
  console.log("ready");
  event.target.playVideo();
}

window.onPlayerStateChange = function(event) {
  if(event.data == YT.PlayerState.ENDED){
    $("#playerWrap").addClass('d-none');
    $("#playerImg").removeClass('d-none');
  }
}

window.stopVideo = function() { 
  player.stopVideo();
}

// function getYoutubeID(url){
//   var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
//   var match = url.match(regExp);
//   return (match&&match[7].length==11)? match[7] : false;
// }
