import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function() {
    if($(".gallery-slide").length > 0){
        $(".gallery-slide").slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            swip: false,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
        });
    } // end gallery-slide

    if($(".rel-posts").length > 0){
      relSlide();
      
      $(window).on("resize", function(){
          relSlide();
      }) // end resize
    } // end if
});

function relSlide(){
  var settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swip: false,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  var relSlide = $(".rel-posts");

  if($(window).width() >= 768){
    if (relSlide.hasClass('slick-initialized')) {
      relSlide.slick("unslick");
    }
  }else if(!relSlide.hasClass('slick-initialized')) {
    relSlide.slick(settings);
  }
} // end function
