//https://stackoverflow.com/questions/65194579/create-new-event-with-modal-in-fullcalendar-with-rails-6-and-bootstrap-4

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

document.addEventListener('DOMContentLoaded', function() {
  if($("#calendar").length > 0){
    
    var calendarEl = document.getElementById('calendar');

    var calendar = new Calendar(calendarEl, {
      displayEventTime: false,
      plugins: [ dayGridPlugin ],
      height: "auto",
      initialView: 'dayGridMonth',
      events: 'zh-TW?format=json'
    });
    calendar.render();
  }
});