$(function(){
    $("body").on("click", "#menuBtn", function(e){
        var target = $(this).data("target");

        $(target).toggleClass("show");
        $(this).toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#header").toggleClass("open");
        $("#mainmenu").toggleClass("show");
        e.preventDefault();
    })

    $(".has").on("click", function(e){
        if($(window).width() < 1140){
            $(this).parents(".mainmenu-item").find(".submenu-wrap").toggleClass("show");
            $(this).toggleClass("open");
            e.preventDefault();
        }
    })

    $("body").on("click", ".gotop", function(e){
        $("body, html").animate({
            scrollTop: 0
        }, 600);
        e.preventDefault();
    })

    if($(".needs-validation").length > 0){
        $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
    }

    $('[data-bs-toggle="tooltip"]').tooltip();

    $(".search-btn").on("click", function(e){
        $(".search-form").addClass("open");
        $(".lang-switch").addClass("move");
        document.querySelector('.search-input').focus();

        if($(this).hasClass("disable2")){
            e.preventDefault();
        }
        $(this).attr("type","submit").removeClass("disable2");
    });

    $(".top-form").on("focus", "input", function(){
        $(this).val('');
    });

    $(".mobile-toggle").on("click", function(){
        $(this).toggleClass("open");
        var target = $(this).data("target");
        $(target).slideToggle();
    });

    $(".disable").on("click", function(e){
        e.preventDefault();
    })
})